import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { HTMLParser } from "../HTMLParser";
import { useTheme } from "@emotion/react";
import { useJudgeCourtData } from "../../../services/hooks/useJudgeCourtData";

const colors = ["simple", "success", "tertiary", "primary"];

export function JudgeCourtAnalysisSummary({ id, type, isJudgeX, isCourtX }) {
  const theme = useTheme();
  const { tags, shouldHideTags } = useJudgeCourtData(id, type);

  const multiTags = tags?.tag_levels?.tag_list?.filter(
    (tag) => tag.tag_level === 99
  );
  const simpleTags = tags?.tag_levels?.tag_list?.filter(
    (tag) => tag.tag_level !== 99
  );

  function handleHighlightText(id, color) {
    const element = document.getElementById(id.replace(" ", "-").toLowerCase());
    if (element) element.style.backgroundColor = `${color}25`;
  }
  function handleUnHighlightText(id) {
    const element = document.getElementById(id.replace(" ", "-").toLowerCase());
    if (element) element.style.backgroundColor = `transparent`;
  }

  return (
    <Stack sx={{ gap: 4, pb: 5 }}>
      {shouldHideTags(isCourtX, isJudgeX) ? (
        <Typography variant="body1">
          {`The sample size for this ${type} is too limited to accurately summarize. To request an increase in sample size, please contact Rhetoric with your request at `}
          <a href="mailto:support@userhetoric.com">support@userhetoric.com</a>.
        </Typography>
      ) : (
        <>
          <Stack sx={{ gap: 2 }}>
            {multiTags && multiTags.length > 0 && (
              <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                {multiTags.map((tag) => (
                  <Tooltip key={tag.tag_text} title={tag.tag_hover}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      sx={{
                        borderRadius: 5,
                        px: 3,
                        pb: 0.3,
                        ":hover": { backgroundColor: "secondary.main" },
                      }}
                      onMouseEnter={() =>
                        handleHighlightText(
                          tag.tag_text,
                          theme.palette.secondary.main
                        )
                      }
                      onMouseLeave={() => handleUnHighlightText(tag.tag_text)}
                    >
                      {tag.tag_text}
                    </Button>
                  </Tooltip>
                ))}
              </Stack>
            )}
            {!!multiTags?.length && !!simpleTags?.length && <Divider />}
            {simpleTags && simpleTags.length > 0 && (
              <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                {simpleTags.map((tag) => (
                  <Tooltip key={tag.tag_text} title={tag.tag_hover}>
                    <Button
                      cy-data="judge-tag-btn"
                      size="small"
                      variant="contained"
                      color={colors[tag.tag_level - 1]}
                      onMouseEnter={() =>
                        handleHighlightText(
                          tag.tag_text,
                          theme.palette[colors[tag.tag_level - 1]].main
                        )
                      }
                      onMouseLeave={() => handleUnHighlightText(tag.tag_text)}
                    >
                      {tag.tag_text}
                    </Button>
                  </Tooltip>
                ))}
              </Stack>
            )}
          </Stack>
          {tags?.tag_levels?.tag_summary && (
            <HTMLParser content={tags.tag_levels.tag_summary} />
          )}
        </>
      )}
    </Stack>
  );
}
