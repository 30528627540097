import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  JudgesCard,
  ResetJudgesSearch,
  SearchEmpty,
  SearchJudges,
} from "../../components";
import { NavLink as RouterLink, useSearchParams } from "react-router-dom";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import {
  canCompareJudgesState,
  compareJudgesState,
  drawerJudgeSearchState,
} from "../../../services/store";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { Default } from "../../layouts";
import { Error } from "../../components/Error/Error";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Grid from "@mui/material/Unstable_Grid2";
import { TextSearchJudges } from "../../components/TextSearchJudges";
import { useInfiniteQuery } from "@tanstack/react-query";

const JudgesView = () => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useAtom(
    drawerJudgeSearchState
  );

  const handleCloseFilterDrawer = () => setFilterDrawerOpen(false);

  return (
    <Default>
      <Drawer
        anchor={"right"}
        open={filterDrawerOpen}
        onClose={handleCloseFilterDrawer}
        variant="persistent"
      >
        <Suspense>
          <SearchJudges />
        </Suspense>
      </Drawer>
      <Error>
        <Fetch />
      </Error>
    </Default>
  );
};

function Fetch() {
  const [page, setPage] = useState(1);
  const [search] = useSearchParams();

  const searchParamsString = new URLSearchParams(search).toString();

  const isComparing = useAtomValue(canCompareJudgesState);
  const comparing = useAtomValue(compareJudgesState);

  const setFilterDrawerOpen = useSetAtom(drawerJudgeSearchState);
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    isLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["judges", searchParamsString],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(
        `/v3/judges?page=${pageParam}${
          searchParamsString ? `&${searchParamsString}` : ""
        }`
      ),
    getNextPageParam: (lastPage) => {
      return lastPage.num_pages === page ? undefined : page + 1;
    },
    throwOnError: true,
  });

  const handleOpenFilterDrawer = () => setFilterDrawerOpen(true);

  const handleFetchMore = useCallback(() => {
    const next = page + 1;
    setPage(next);
    fetchNextPage();
  }, [fetchNextPage, page]);

  useEffect(() => {
    if (isRefetching) {
      setPage(1);
    }
  }, [isRefetching]);

  const items = useMemo(() => {
    return data?.pages?.map((data) => data.judges).flat() || [];
  }, [data]);

  return (
    <Stack>
      <Box
        paddingY={[2, 4]}
        paddingX={[0, 4]}
        position="sticky"
        top={0}
        backgroundColor="white"
        zIndex={10}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Stack
              alignItems={["stretch", "stretch", "center"]}
              direction={["column", "column", "row"]}
              justifyContent="space-between"
              paddingX={[2, 2, 0]}
              gap={[2, 3]}
            >
              <Stack
                flex={1}
                alignItems={["stretch", "stretch", "center"]}
                direction={["row"]}
                gap={[0, 0, 3]}
              >
                <Box flex={1}>
                  <TextSearchJudges />
                </Box>
                <Stack alignItems="center" direction="row" gap={0}>
                  <IconButton onClick={handleOpenFilterDrawer}>
                    <FilterAltIcon />
                  </IconButton>
                  {items && <ResetJudgesSearch />}
                </Stack>
              </Stack>
              {isComparing && (
                <Button
                  to="/judges/compare"
                  component={RouterLink}
                  variant="contained"
                  fullWidth={!smUp}
                  data-cy="main-compare-button-judges"
                >
                  Compare ({comparing.length})
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor={["white", "grey.100"]} padding={[0, 4]}>
        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="80vh"
          >
            <CircularProgress size="3rem" />
          </Box>
        )}
        {!isLoading && items?.length === 0 && (
          <Grid container rowSpacing={4}>
            <Grid xs={12}>
              <SearchEmpty text="No Judges found that fit your search." />
            </Grid>
          </Grid>
        )}
        <Grid container rowSpacing={4} xs={12}>
          {items?.map((id) => (
            <Grid key={id} xs={12}>
              <JudgesCard id={id} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {(isFetchingNextPage || hasNextPage) && items && items?.length !== 0 && (
        <Stack sx={{ py: 4 }}>
          <Button onClick={handleFetchMore} variant="contained">
            {isFetchingNextPage ? (
              <CircularProgress color="inherit" size="3rem" />
            ) : (
              "Load More"
            )}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export const Judges = () => <JudgesView />;
